.DateLine {
	display: flex;
	align-items: center;
	justify-content: start;
	flex-direction: row;
	min-width: 100px;
	white-space: nowrap;

	&__icon {
		margin-right: 0.5rem;
		color: #464646;
	}
}
