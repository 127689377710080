.RefundPage {
	padding: 30px 0 100px 0;
}

.OrderDetails {
	margin: 30px 0;

	p {
		margin: 0;
	}

	&__header {
		margin-bottom: 20px;
	}

	&__label {
		font-size: 15px;
	}

	&__value {
		line-height: 40px;
	}
}

.SelectionSection {
	padding-bottom: 70px;

	.RadioLabel {
		font-size: 18px;
		line-height: 24px;
	}
}

.RefundForm {
	margin: 30px 0;

	&__row {
		margin-top: 25px;

		&--aligned {
			display: flex;
			align-items: center;

			.RefundAmount {
				width: 250px;
				text-align: right;
			}
		}
	}

	&__input {
		width: 250px;

		// temp fix to hide validation icon on input
		// TODO: remove it when the issue HNY-299 is fixed
		.hcr-input__feedback-icon-10-0-1 {
			display: none;
		}

		input {
			text-align: right;
		}
	}

	&__checkbox {
		margin-top: 70px;
	}

	&__submit {
		margin-top: 30px;
	}
}

.RefundTableOption {
	padding-left: 30px;
	margin-bottom: 50px;

	&--disabled {
		opacity: 0.6;
		z-index: -1;
	}
}
.CustomRefundOption {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
