.commentEditButtons {
	margin: 10px 0;
}

.CommentTextCell {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	p {
		margin: 0;
		text-align: left;
	}

	.CommentContent {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: center;
		min-width: 150px;
		word-wrap: break-word;

		.CommentCreated {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			color: rgb(75, 74, 74);

			.CommentAuthor {
				font-weight: bold;
				padding-right: 5px;
			}
		}
		.CommentEdited {
			padding-top: 5px;
			font-size: 12px;
			color: rgb(75, 74, 74);
		}

		.CommentLabel {
			margin-left: 12px;
		}
	}
}
