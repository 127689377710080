.OrderIdInput {
    margin-bottom: 10px;
}

.FeatureBox {
	position: relative;
	min-height: 370px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	border: none;
	margin: 30px;
}

.FeatureBody {
	margin-top: 60px;
	width: 100%;
    padding: 10px 0 20px 0;
}

.imageWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.FeatureImage {
		position: absolute;
		top: -65px;
		width: 55%;
		margin: auto;
	}
}

@media (max-width: 600px) {
	.imageWrapper {
		img.FeatureImage {
			margin-top: 15px;
		}

		&:last-child {
			img.FeatureImage {
				top: -55px;
			}
		}
	}
}

