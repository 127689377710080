.SectionHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;

	&__name {
		display: flex;
		align-items: center;
		padding-left: 12px;

		.HeaderIcon {
			margin-right: 10px;
		}
	}
}
