.EmailBox {
	min-width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 12px;

	p {
		margin-bottom: 5px;
	}
}

.EmailStatus {
	border-left: 1px solid #e0e0e0;
	padding-left: 15px;
}

.InfoTag {
	margin-left: 8px;
}

.NoInfoMessage {
	padding: 30px 0;
}
