.RouteInfo {
	display: flex;
	align-items: center;
	justify-content: start;
	flex-direction: row;
	min-width: 110px;
	word-break: keep-all;
	white-space: nowrap;

	&__icon {
		margin-right: 0.5rem;
		color: #464646;
	}

	.InterconnectionLabel {
		margin-left: 8px;
	}
}

.InterconnectionRow {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	margin-bottom: 20px;

	&__icon {
		margin-right: 0.5rem;
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: start;
		justify-content: start;
	}
	
	&__ride {
		margin-right: 10px;
	}
}
