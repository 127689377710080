.Timestamp {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__icon {
		margin-right: 5px;
	}

	&__time {
		margin-left: 5px;
	}
}
