.CommentsHistory {
	max-height: 40vh;
	overflow-y: scroll;
}

.CommentRow {
	padding: 10px 0;
	border-bottom: 1px solid #c8c8c8;

	&:last-child {
		border: none;
	}
}
