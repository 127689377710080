.NoResultsBox {
    min-height: 20vh;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h5 {
        font-size: 20px;
        margin-top: 10px;
    }
    img {
        width: 15%;
        margin-bottom: 10px;
    }
}
