.PaymentsDropdown {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-start;
	margin-bottom: 10px;

	&__additionalMenu {
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover,
		&:focus,
		&:active {
			cursor: pointer;
			box-shadow: none;
		}

		.Dots {
			font-weight: bold;
			font-size: 20px;
			position: absolute;
			top: 39%;
			height: 0;
			line-height: 0;
		}
	}
}
