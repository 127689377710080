.UnderConstructionContainer {
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 30px;

	@media (max-width: 550px) {
		min-height: 83vh;
	}
}
