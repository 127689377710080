.TransferTime {
	border: 1px solid #353535;
	border-radius: 6px;
	padding: 6px 12px;
	display: flex;
	align-items: center;
	justify-content: center;

	.TransferIcon {
		margin-right: 10px;
	}
}
.InterconnectionDetails {
	padding: 20px 35px;

	&__header {
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
		margin: 0;
	}

	.FullRoute {
		position: relative;
		margin: 20px 0;

		&__time {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__station {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&__stopName {
				max-width: 140px;

				&--left {
					text-align: left;
				}

				&--right {
					text-align: right;
				}
			}
		}

		&__line {
			width: 90%;
			border-top: 1px solid #c8c8c8;
		}

		&__duration {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			// 43px - half of the time box width
			left: calc(50% - 43px);
			top: -3px;
			background-color: white;
			// content-secondary
			color: #646464;
			font-weight: 600;
			// border: 1px solid #c8c8c8;
			padding: 2px 5px;
		}
	}

	.InterconnectionRoute {
		margin: 15px 0;

		&__stopName {
			margin-right: 20px;
		}

		&__details {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			&__price {
				margin-top: 8px;
				justify-content: flex-start;
			}

			&__icon {
				margin-right: 10px;
			}
		}

		&__departure {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&__icon {
				margin-right: 5px;
			}

			&__time {
				margin-left: 5px;
			}
		}
	}
}

.CommentsCell {
	vertical-align: top;

	.CommentsDetails {
		padding: 20px 0;
	}
}
