.OrderItemIdCell {
	display: flex;
   align-items: center;
   justify-content: flex-end;
}

tr.OrderDetailsRow {
	background-color: #f7f7f7 !important;

	&:hover {
		background-color: #f7f7f7 !important;
	}
}

.OrderItemToggleRowButton {
   &:hover, &:focus {
      box-shadow: none !important;
   }
}
