.testLabel {
    margin-left: 10px;
}

.InfoMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 120px;
    padding-bottom: 30px;

    span {
        font-size: 20px;
    }
}