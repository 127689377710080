.OrderLinks {
	margin-top: 10px;

	p {
		font-size: 14px;
	}
}
.OrderDate {
	min-width: 100px;
}
.RebookedLabel {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 5px;
}
