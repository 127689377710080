.OrderInfoTable {
	overflow: auto;
	background-color: white;
	min-width: 100%;
	margin-bottom: 12px;

	table {
		width: 100%;
	}

	th,
	td {
		padding: 12px;
		border-bottom: 1px solid #c8c8c8;
	}

	tr:last-child {
		td,
		th {
			border-bottom: none;
		}
	}

	.StatusNote {
		word-break: break-word;
	}
}

.additionalStatus {
	margin-left: 10px;
}
