.orderPrice {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	min-width: 160px;

	.orderPrice__label {
		margin-right: 5px;
	}
}

.orderItemPhone {
	min-width: 100px;
}
