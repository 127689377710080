.RefundNotification {
	max-width: 380px;

	&__title {
		font-weight: bold;
	}

	&__text {
		font-weight: lighter;
	}
}
