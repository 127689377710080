@import "../node_modules/bootstrap/scss/bootstrap";
body {
	background-color: #f7f7f7;
	font-family: "Roboto", sans-serif;
}

a:hover {
	color: inherit !important;
}

.SignInBtn {
	margin-top: 12px;
}

.LoginBlock {
	min-height: calc(100vh - 420px);
}

@media only screen and (max-width: 690px) {
	.LoginBlock {
		min-height: 79vh;
	}
}

.VoucherSearchForm {
	background-color: white;
	padding: 50px 150px;
}

.Loader {
	height: 340px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.LongLine {
	white-space: nowrap;
}

.rideIdLink {
	text-decoration: none;
	color: white;

	&:hover {
		text-decoration: underline;
		color: white;
	}
}

.PageWrapper {
	padding: 0 20px;
}

.CalendarRelativeWrapper {
	position: relative;
}

.CalendarWrapper {
	max-width: 300px;
	position: absolute;
	z-index: 1000;
}

.bigBtn {
	min-height: 48px;
}

.dropdownIcon {
	margin: 0 10px;
}

.SearchWrapper {
	background-color: white;
	padding-bottom: 30px;
	margin-bottom: 10px;
}

.SearchHeader {
	padding: 24px 0;
	margin: 0;
}

.text-center {
	text-align: center;
}

.d-none {
	display: none;
}

// tables on Order Details page
.SectionTable {
	margin-bottom: 12px;
}

.margin-0 {
	margin: 0;
}

.hidden {
    display: none;
}
