.OrderDetailsHeading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	// TODO: remove this when turn off bootstrap
	margin-top: 1rem;

	h1 {
		padding: 0;
	}
}

.OrderActionButtons {
	display: flex;
	align-items: center;
	justify-content: end;
}

.OrderIdHeader {
	display: flex;
	align-items: center;
	justify-content: start;
}

// remove flex style for mobile
@media screen and (max-width: 768px) {
	.OrderActionButtons {
		display: inline-block;
		padding-top: 20px;
	}
}

.TableSection {
	padding-bottom: 0;
	padding-top: 4px;
}
